import { FC, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Footer } from "./components/footer/Footer";
import { Navbar } from "./components/navbar/Navbar";
import { CompleteProfile } from "./views/completeProfile/CompleteProfile";
import { DriverReservation } from "./views/driverReservation/show/component";
import { DriverReservations } from "./views/driverReservation/index/component";
import { Profile } from "./views/profile/Profile";
import { Results } from "./views/results/Results";
import { SignUp } from "./views/signup/Signup";
import { CreateReservation } from "./views/driverReservation/create/CreateReservation";
import { Verification } from "./views/verification/Verification";
import { Car } from "./views/car/show/ShowCar";
import { CarIndex } from "./views/car/index/component";
import { AddCar } from "./views/car/add/AddCar";
import { EditCar } from "./views/car/edit/EditCar";
import { HostReservations } from "./views/hostReservation/index/component";
import { HostReservation } from "./views/hostReservation/show/component";
import CheckoutSuccessful from "./views/driverReservation/success/component";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ProtectedRoute } from "./lib/components/protectedRoute/component";
import useAnalytics from "./hooks/useAnalytics";
import InProgress from "./components/inProgressView/InProgressView.component";
import ForgotPassword from "./views/resetPassword/forgotPassword/ForgotPassword";
import SendEmailConfirmation from "./views/resetPassword/sendEmailConfirmation/SendEmailConfirmation";
import ResetPasswordForm from "./views/resetPassword/resetPasswordForm/ResetPasswordForm";
import { LoginContext, useRoot } from "./hooks/root";
import { PageNotFound } from "./views/pageNotFound/PageNotFound";
import { KycSuccess } from "./views/pagesKYC/KycSuccess/KycSuccess";
import { LicenseInfo } from "@mui/x-license-pro";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { Chat } from "./views/chat/Chat";
import { DomainContext } from "./providers/domainProvider";
import { AdminNavbar } from "./components/adminNavbar/AdminNavbar";
import { AuthProvider } from "./providers/authProvider";
import { ClientIndex } from "./views/clients/index";
import { ShowClient } from "./views/clients/show/show";
import { Portal } from "./views/portal/Portal";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI || "");

export const App: FC = () => {
  const { settings } = useContext(DomainContext);
  const [stripeObject, setStripeObject] = useState<
    Stripe | PromiseLike<Stripe | null> | null
  >(null);
  const {
    isLogged,
    value,
    user,
    t,
    isAuthorized,
    isComplete,
    theme,
    setTheme,
    sendBirdData,
  } = useRoot();

  useEffect(() => {
    if (settings) {
      setStripeObject(
        loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "", {
          stripeAccount: settings.payment.stripeAccount,
        })
      );
    }
  }, [settings]);
  // Enable analytics
  useAnalytics(user);

  return (
    <Elements stripe={stripeObject}>
      <LoginContext.Provider value={value}>
        <AuthProvider>
          {isAuthorized ? <AdminNavbar /> : <Navbar />}
        </AuthProvider>
      </LoginContext.Provider>
      <Routes>
        <Route
          path={t("root")}
          element={
            isAuthorized ? (
              <Navigate to={t("admin")} replace />
            ) : (
              <Navigate to={t("results")} replace />
            )
          }
        />
        {/* CLIENT ROUTES */}
        {settings.advancedFeatures.chat && (
          <Route
            path={t("chat")}
            element={
              sendBirdData && (
                <SendbirdProvider
                  theme={
                    theme.toLocaleLowerCase() as "light" | "dark" | undefined
                  }
                  breakpoint={"900px"}
                  appId={process.env.REACT_APP_APP_ID || ""}
                  userId={sendBirdData.id}
                >
                  <Chat />
                </SendbirdProvider>
              )
            }
          />
        )}
        <Route
          path={t("trips")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <DriverReservations />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("verify")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <Verification />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("profile")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("recover_password")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <ForgotPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("sent_recover_password")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <SendEmailConfirmation />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("reset_password")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <ResetPasswordForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("signup")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={!isLogged}>
              <LoginContext.Provider value={value}>
                <SignUp />
              </LoginContext.Provider>
            </ProtectedRoute>
          }
        />
        <Route
          path={t("complete_profile")}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={isLogged && !isComplete}
            >
              <LoginContext.Provider value={value}>
                <CompleteProfile />
              </LoginContext.Provider>
            </ProtectedRoute>
          }
        />
        <Route
          path={t("trips") + "/:reservationId"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <DriverReservation />
            </ProtectedRoute>
          }
        />
        <Route path={t("details") + "/:carId"} element={<Car />} />
        <Route
          path={t("results")}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={!isAuthorized}
            >
              <Results />
            </ProtectedRoute>
          }
        />
        <Route path={t("favorites")} element={<InProgress />} />
        <Route
          path={"checkout/checkout_successful"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <CheckoutSuccessful />
            </ProtectedRoute>
          }
        />
        <Route
          path={"checkout/checkout_failed"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <CheckoutSuccessful />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("checkout") + "/:tripId"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <CreateReservation />
            </ProtectedRoute>
          }
        />
        <Route path={t("not_found")} element={<PageNotFound />} />
        <Route path={t("kyc_success")} element={<KycSuccess />} />

        {/* ADMIN ROUTES */}
        <Route path={`${t("admin")}`} element={<InProgress />} />
        <Route path={`${t("manage")}`}>
          <Route path={`${t("my_cars")}`}>
            <Route
              path={""}
              element={
                <ProtectedRoute
                  redirectPath={t("not_found")}
                  isAllowed={isLogged && isAuthorized}
                >
                  <CarIndex />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${t("upload_car")}`}
              element={
                <ProtectedRoute
                  redirectPath={t("not_found")}
                  isAllowed={isLogged && isAuthorized}
                >
                  <AddCar />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${t("edit") + "/:carId"}`}
              element={
                <ProtectedRoute
                  redirectPath={t("not_found")}
                  isAllowed={isLogged && isAuthorized}
                >
                  <EditCar />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={`${t("clients")}`}>
            <Route path={""} element={<ClientIndex />} />
            <Route path={":id"} element={<ShowClient />} />
          </Route>
          <Route path={`${t("trips")}`}>
            <Route
              path={""}
              element={
                <ProtectedRoute
                  redirectPath={t("not_found")}
                  isAllowed={isLogged && isAuthorized}
                >
                  <HostReservations />
                </ProtectedRoute>
              }
            />
            <Route
              path={":HostReservationId"}
              element={
                <ProtectedRoute
                  redirectPath={t("not_found")}
                  isAllowed={isLogged && isAuthorized}
                >
                  <HostReservation />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={`${t("calendar")}`} element={<InProgress />} />
          <Route path={`${t("progress")}`} element={<InProgress />} />
          <Route path={`${t("branches")}`} element={<InProgress />} />
          <Route path={`${t("groups")}`} element={<InProgress />} />
        </Route>

        <Route path={`${t("sell")}`}>
          <Route path={`${t("my_portal")}`} element={<Portal />} />
          <Route path={`${t("discounts")}`} element={<InProgress />} />
          <Route path={`${t("down_payment")}`} element={<InProgress />} />
          <Route path={`${t("policies")}`}>
            <Route path={`${t("cancellations")}`} element={<InProgress />} />
            <Route path={`${t("extensions")}`} element={<InProgress />} />
            <Route path={`${t("modifications")}`} element={<InProgress />} />
            <Route path={`${t("penalties")}`} element={<InProgress />} />
          </Route>
          <Route path={`${t("products")}`} element={<InProgress />} />
          <Route path={`${t("payouts")}`} element={<InProgress />} />
          {settings.advancedFeatures.chat && (
            <Route
              path={`${t("chat")}`}
              element={
                sendBirdData && (
                  <SendbirdProvider
                    theme={
                      theme.toLocaleLowerCase() as "light" | "dark" | undefined
                    }
                    breakpoint={"900px"}
                    appId={process.env.REACT_APP_APP_ID || ""}
                    userId={sendBirdData.id + "-Host"}
                  >
                    <Chat />
                  </SendbirdProvider>
                )
              }
            />
          )}
        </Route>

        <Route path={`${t("protect")}`}>
          <Route path={`${t("kyc")}`} element={<InProgress />} />
          <Route path={`${t("insurances")}`} element={<InProgress />} />
          <Route path={`${t("gps")}`} element={<InProgress />} />
        </Route>

        <Route path={`${t("grow")}`}>
          <Route path={`${t("romio")}`}>
            <Route path={`${t("")}`} element={<InProgress />} />
            <Route path={`${t("listed_cars")}`} element={<InProgress />} />
          </Route>
          <Route path={`${t("other_marketplaces")}`} element={<InProgress />} />
        </Route>

        <Route path={`${t("ai")}`}>
          <Route path={`${t("internal_resources")}`} element={<InProgress />} />
          <Route path={`${t("sales_agent")}`} element={<InProgress />} />
          <Route path={`${t("customer_service")}`} element={<InProgress />} />
          <Route
            path={`${t("business_intelligence")}`}
            element={<InProgress />}
          />
        </Route>

        <Route path={`${t("settings")}`}>
          <Route path={`${t("my_company")}`}>
            <Route path={""} element={<Profile />} />
          </Route>
          <Route path={`${t("plans")}`} element={<InProgress />} />
          <Route path={`${t("equipments")}`} element={<InProgress />} />
        </Route>
      </Routes>
      {!isAuthorized && <Footer setTheme={setTheme} theme={theme} />}
    </Elements>
  );
};
